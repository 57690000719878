$background: #f7f5f5;
$border: #e8e7e6;
$connectedColor: #008C73;
$disabled: #5D6D74;
$error: #f02525;
$fancy: #f02525;
$fontColor: #001428;
$primary: #001428;
$secondary: #008C73;
$secondaryTextOrSvg: #B2B5B2;
$secondaryBackground: #f0efee;
$warning: #ffc05f;
$fancy: #f02525;
$secondary: #008C73;

$headerHeight: 53px;
$marginButtonImg: 12px;

$lg: 24px;
$md: 16px;
$sm: 8px;
$xl: 32px;
$xs: 4px;
$xxl: 40px;
$xxlFontSize: 32px;

$bolderFont: 500;
$extraBoldFont: 800;

$fontSizeHeadingLg: 32px;
$fontSizeHeadingMd: 20px;
$fontSizeHeadingSm: 16px;
$fontSizeHeadingXs: 13px;
$mediumFontSize: 14px;

$smallFontSize: 12px;
$largeFontSize: 16px;
$extraLargeFontSize: 20px;
$extraSmallFontSize: 11px;


$lightFont: 300;
$regularFont: 400;
$boldFont: 700;

$screenLg: 1200;
$screenMd: 992;
$screenSm: 768;
$screenXs: 480;